import { NavLink } from "react-router-dom";
import "../styles/Nav.css";

function NavMenu() {
  return (
    <nav>
      <ul>
        <li>
          <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
            <img src="/assets/feed.svg" alt="feed" width={25} />
            <span>Лента</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/timetable" className={({ isActive }) => (isActive ? 'active' : '')}>
            <img src="/assets/calendar.svg" alt="calendar" width={25} />
            <span>Расписание</span>
          </NavLink>
        </li>

        <li>
          <NavLink to="/media" className={({ isActive }) => (isActive ? 'active' : '')}>
            <img src="/assets/vinyl.svg" alt="vinyl" width={25} />
            <span>Медиа</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default NavMenu;
