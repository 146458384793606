function Loader() {
  return (
    <div className="App">
      <div className="App-header">
        <img src="/logo_main512.png" className="App-logo" alt="logo" />
      </div>
    </div>
  );
}

export default Loader;
