import { Alert, Typography } from "@mui/material";
import TelegramLoginButton from "../components/TelegramLoginButton";
import "../styles/Feed.css";
import React from "react";
import { MainAppContext } from "../providers/MainAppProvider";

export default class Feed extends React.Component<any> {
  static contextType = MainAppContext;
  // declare context: React.ContextType<typeof MainAppContext>
  constructor(public context: React.ContextType<typeof MainAppContext>, props: any) {
    super(props);
  }

  public render() {
    const { isLoggedIn } = this.context;
    return (
      <div className="feed">
        <img src="/logo_main192.png" className="feed_logo" width={80} alt="logo" />

        <div className="feed_block">
          <div className="post">
            <Typography variant="h6" style={{ marginBottom: 10 }}>
              Информация
            </Typography>
            <Alert severity="warning" style={{ marginBottom: 10 }} variant="outlined" >
              Для бронирования репетиций требуется авторизоваться через Телеграм
            </Alert>

            <Alert severity="warning" style={{ marginBottom: 10, marginTop: 10 }} variant="outlined" >
              Бронирование репетиций на данный момент происходит без модерации и
              подтверждений.
            </Alert>
            <Alert severity="info" style={{ marginBottom: 10 }} variant="outlined" >
              Для установки приложения на свое устройство легче использовать
              Google Chrome. Нажать три точки, затем "Установить страницу как
              приложение."
            </Alert>
            <Alert severity="info" style={{ marginBottom: 10 }} variant="outlined" >
              Для установки на iOS надо нажать кнопку поделиться и выбрать
              "Отправить на экран Домой"
            </Alert>
            {!isLoggedIn && (
              <div style={{ textAlign: "center" }}>
                <TelegramLoginButton />
              </div>
            )}
            <div className="date">
              <span>
                <b>16.10.2024</b>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
