import React from "react";

export default class TelegramLoginButton extends React.Component {
  public buttonRef = React.createRef<HTMLDivElement>();

  public componentDidMount() {
    (window as any).TelegramLoginWidget = {
      onTelegramAuth: (user: any) => localStorage.setItem("user", JSON.stringify(user)),
    };
    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-widget.js?2";
    script.setAttribute("data-telegram-login", "tuleneva25_bot");
    script.setAttribute("data-size", "large");
    script.setAttribute("data-request-access", "write");
    script.setAttribute("data-userpic", "true");
    script.setAttribute(
      "data-onauth",
      "TelegramLoginWidget.onTelegramAuth(user)"
    );
    script.async = true;
    if (this.buttonRef.current) {
      this.buttonRef.current.appendChild(script);
    }
  }

  render() {
    return <div ref={this.buttonRef} />;
  }
}
