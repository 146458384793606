import React from "react";


export interface MainAppContextProps {
    user: User | null,
    isLoggedIn: boolean,
    setUser: (user: User, isLoggedIn: boolean) => void,
};

interface MainAppProviderProps {
    children: React.ReactNode;
}

type User = {
    id: number,
    first_name: string,
    last_name: string,
    username: string,
    photo_url: string,
    auth_date: number,
    hash: string,
};

type State = {
    user: User | null,
    isLoggedIn: boolean,
};

export const MainAppContext = React.createContext<MainAppContextProps>({
    user: null,
    isLoggedIn: false,
    setUser: () => { },
});

class MainAppProvider extends React.Component<MainAppProviderProps, State> {
    constructor(props: MainAppProviderProps) {
        super(props);
        this.state = {
            user: null,
            isLoggedIn: false,
        };
    }

    public componentDidMount() {
        const user = localStorage.getItem('user');
        if (user) {
            this.setState({ user: JSON.parse(user), isLoggedIn: true });
        }
    }

    public setUser = (user: User, isLoggedIn: boolean) => {
        localStorage.setItem('user', JSON.stringify(user));
        this.setState({ user, isLoggedIn });
    };

    public render() {
        const { user, isLoggedIn } = this.state;
        return (
            <MainAppContext.Provider
                value={{
                    user,
                    isLoggedIn,
                    setUser: this.setUser,
                }}
            >
                {this.props.children}
            </MainAppContext.Provider>
        );
    }
}

export default MainAppProvider;