import React from "react";
import { createPortal } from "react-dom";
import useIosInstallPrompt from "../hooks/useIosInstallPrompt";
import useWebInstallPrompt from "../hooks/useWebInstallPrompt";
import "../styles/InstallPrompt.css";

export const InstallPWA = () => {
  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] =
    useWebInstallPrompt();

  if (!iosInstallPrompt && !webInstallPrompt) {
    return null;
  }

  return createPortal(
    <div className="installprompt">
      <div className="installpromptmodal">
        <img
          className="mx-auto"
          width="100px"
          src="/logo_main192.png"
          alt="Тюленева 25"
        />
        <h3>Установить приложение</h3>
        {iosInstallPrompt && (
          <>
            <div className="text-center">
              Нажмите
              <img
                src="/assets/share.svg"
                style={{ margin: "auto 8px" }}
                className=""
                alt="Add to homescreen"
                width="20"
              />
              затем &quot;Добавить на рабочий стол&quot;
              или на &quot;на экран домой&quot;
            </div>
            <div className="d-flex justify-content-center">
              <button onClick={handleIOSInstallDeclined}>Закрыть</button>
            </div>
          </>
        )}
        {webInstallPrompt && (
          <div className="d-flex justify-content-around">
            <button color="primary" onClick={handleWebInstallAccepted}>
              Установить
            </button>
            <button onClick={handleWebInstallDeclined}>Закрыть</button>
          </div>
        )}
      </div>
    </div>,
    document.body
  );
};
